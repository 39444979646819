import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { setItemInLocalStorage } from '../../../utils';
import { useHistory } from 'react-router-dom';

export default function useReferral() {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const refParam = searchParams.get('ref');
        const mbsySourceParam = searchParams.get('mbsy_source');
        const campaignIdParam = searchParams.get('campaignid');
        const mbsyParam = searchParams.get('mbsy');
        const mbsyExpParam = searchParams.get('mbsy_exp');
        if (refParam) {
            setItemInLocalStorage('trainxfood-ref', refParam);
            searchParams.delete('ref');

            history.replace({
                search: searchParams.toString(),
            });
            if(mbsySourceParam && campaignIdParam && mbsyParam && mbsyExpParam){
                setItemInLocalStorage('trainxfood-mbsy-source', mbsySourceParam);
                setItemInLocalStorage('trainxfood-mbsy-campaignid', campaignIdParam);
                setItemInLocalStorage('trainxfood-mbsy', mbsyParam);
                setItemInLocalStorage('trainxfood-mbsy-exp', mbsyExpParam);
            }
        }
        if(mbsySourceParam && campaignIdParam && mbsyParam && mbsyExpParam){
            setItemInLocalStorage('trainxfood-mbsy-source', mbsySourceParam);
            setItemInLocalStorage('trainxfood-mbsy-campaignid', campaignIdParam);
            setItemInLocalStorage('trainxfood-mbsy', mbsyParam);
            setItemInLocalStorage('trainxfood-mbsy-exp', mbsyExpParam);
          }
    }, [ location.search ]);
}
