import React, { useEffect, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import clsx from 'clsx';

import FormInputLabeled from 'src/components/LR/forms/form-input-labeled/form-input-labeled.component';
import FormTextarea from 'src/components/LR/forms/form-textarea/form-textarea.component';
import Notification from 'src/components/Notification';

import { ReactComponent as LocationIcon } from 'src/assets/media/icons/location.svg';
import { ReactComponent as EmailIcon } from 'src/assets/media/icons/email-outline.svg';

import { validationSchemaContacts } from './contacts.utils';
import { sendEnquiry } from 'src/hooks/api/sendEnquiry/sendEnquiry';
import api from 'src/managers/api';
import logger from 'src/managers/logger.manager';

import styles from './Contacts.module.sass';


const Contacts = () => {
    const [ showNotification, setShowNotification ] = useState(false);
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name:  '',
            email:      '',
            phone:      '',
            message:    '',
        },
        validationSchema: validationSchemaContacts,
        onSubmit:         async(values, actions) => {
            const grecaptcha = (window as any).grecaptcha;
            if (typeof grecaptcha !== 'undefined') {
                grecaptcha.ready(function() {
                    grecaptcha.execute('6LfCweEeAAAAADWmWUWqYP6DXA3tmmbUGkVDKTol', {action: 'submitContactForm'}).then(async function(token: any) {
                        try {
                            const verifyRes =  await api.post(`${process.env.REACT_APP_ALLRIGHT_BASE_API_URL}/recaptcha/verify`, { token });
                            if (verifyRes.data.success) {
                                const response = await sendEnquiry(values);
                                if (response.id) {
                                    setShowNotification(true);
                                    actions.resetForm();
                                }
                            }
                        } catch (error) {
                            logger.error(error);
                        }
                    });
                });
            }
        },
    });

    const serviceHours = useMemo(() => [
        {
            day:   'Monday',
            hours: '08:00 AM - 08:00 PM',
        },
        {
            day:   'Tuesday',
            hours: '08:00 AM - 08:00 PM',
        },
        {
            day:   'Wednesday',
            hours: '08:00 AM - 08:00 PM',
        },
        {
            day:   'Thursday',
            hours: '08:00 AM - 08:00 PM',
        },
        {
            day:   'Friday',
            hours: '08:00 AM - 08:00 PM',
        },
        {
            day:   'Saturday',
            hours: '08:00 AM - 08:00 PM',
        },
        {
            day:   'Sunday',
            hours: '08:00 AM - 08:00 PM',
        },
    ], []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className = { styles.container }>
            <div className = { styles.contactDarkContainer } />
            <Notification
                className = { styles.notification }
                message = 'Your message sent!'
                show = { showNotification }
                timeout = { 5000 }
                type = 'success'
                onCloseHandler = { () => setShowNotification(false) }
            />
            <div className = { styles.contactFormContainer }>
                <div className = { styles.formSection }>
                    <h2>Contact Us</h2>
                    <div className = { styles.formFieldsBox }>
                        <form onSubmit = { formik.handleSubmit }>
                            <FormikProvider value = { formik }>
                                <div className = { styles.formGroup }>
                                    <FormInputLabeled
                                        className = { styles.formInput }
                                        label = 'First Name'
                                        name = 'first_name'
                                        type = 'text'
                                    />
                                    <FormInputLabeled
                                        className = { styles.formInput }
                                        label = 'Last Name'
                                        name = 'last_name'
                                        type = 'text'
                                    />
                                </div>
                                <div className = { styles.formGroup }>
                                    <FormInputLabeled
                                        className = { styles.formInput }
                                        label = 'Phone Number'
                                        name = 'phone'
                                        type = 'number'
                                    />
                                    <FormInputLabeled
                                        className = { styles.formInput }
                                        label = 'Email'
                                        name = 'email'
                                        type = 'text'
                                    />
                                </div>
                                <div>
                                    <FormTextarea
                                        className = { styles.formTextarea }
                                        label = 'Message'
                                        name = 'message'
                                    />
                                </div>
                                <button
                                    className = { styles.buttonPrimary }
                                    type = 'submit'>Send Message
                                </button>
                            </FormikProvider>
                        </form>
                    </div>
                </div>
                <div className = { styles.infoContainer }>
                    <div className = { styles.serviceHoursContainer }>
                        <h4>Service Hours</h4>
                        {
                            serviceHours.map((day, i) => {
                                return (
                                    <div
                                        className = { styles.serviceHour }
                                        key = { i }>
                                        <span>
                                            { day.day }
                                        </span>
                                        <span>
                                            { day.hours }
                                        </span>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className = { styles.getIntouchBox }>
                        <h4>Get In Touch</h4>
                        <div className = { styles.contactInfo }>
                            <span className = { styles.icon }>
                                <LocationIcon />
                            </span>
                            <span>
                                Trainx Fitness Coaching,
                                Office 184-0 MOHAMED SAEED HAREB BLDG
                                Port Saeed, Dubai
                            </span>
                        </div>
                        <div className = { clsx(styles.contactInfo, 'items-center') }>
                            <span className = { styles.icon }>
                                <EmailIcon />
                            </span>
                            <span>
                                support@trainx.com
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
