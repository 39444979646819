import { takeLatest, put, call } from 'redux-saga/effects';

import api from '../../managers/api';
import {
    ACTION_GET_ORDERS_REQUEST,
    ACTION_GET_ORDERS_LOAD,
    ACTION_GET_ORDERS_SUCCESS,
    ACTION_GET_ORDERS_ERROR,
    ACTION_GET_ORDER_DETAILS_REQUEST,
    ACTION_GET_ORDER_DETAILS_LOAD,
    ACTION_GET_ORDER_DETAILS_SUCCESS,
    ACTION_GET_ORDER_DETAILS_ERROR,
    ActionType,
    ACTION_CANCEL_ORDER_REQUEST,
} from '../action-types';

interface IResponse {
    [key: string]: any;
}

export function* sagaOrdersWatcher() {
    yield takeLatest(ACTION_GET_ORDERS_REQUEST, getOrdersWorker);
    yield takeLatest(ACTION_GET_ORDER_DETAILS_REQUEST, getOrderDetailsWorker);
    yield takeLatest(ACTION_CANCEL_ORDER_REQUEST, cancelOrderWorker);
}

function* getOrdersWorker() {
    try {
        yield put({ type: ACTION_GET_ORDERS_LOAD });

        const countries = (yield call(() => api.get('/orders').then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_ORDERS_SUCCESS, payload: countries });
    } catch (error) {
        yield put({ type: ACTION_GET_ORDERS_ERROR, payload: { error }});
    }
}

function* getOrderDetailsWorker({ payload }: ActionType<{ orderId: number }>) {
    try {
      yield put({ type: ACTION_GET_ORDER_DETAILS_LOAD });
  
      const order = (yield call(() =>
        api.get(`/orders/${payload.orderId}`).then((res) => res.data.data)
      )) as IResponse;
  
      yield put({ type: ACTION_GET_ORDER_DETAILS_SUCCESS, payload: order });
    } catch (error) {
      yield put({ type: ACTION_GET_ORDER_DETAILS_ERROR, payload: { error }});
    }
}


function* cancelOrderWorker({ payload }: ActionType<{ orderId: number, onSuccess?: () => void  }>) {
    try {

        const res = (yield call(
            () => api.post(`/orders/${payload.orderId}/cancel`).then((res) => res.data.data)
        )) as IResponse;

        payload.onSuccess && payload.onSuccess()

    } catch (error) {
        yield put({ type: ACTION_GET_ORDERS_ERROR, payload: { error }});
    }
}