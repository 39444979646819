import { useSelector } from 'react-redux';
import { IRootState } from '../../../types/state';
import { ISubscription } from '../../../types/subscription';

interface UseSubscription {
    activeSubscription: ISubscription | null;
}

export default function useSubscription(): UseSubscription {
    const activeSubscription = useSelector((state: IRootState) => state.subscriptions.activeSubscription?.data);

    return {
        activeSubscription,
    };
}
