import { takeLatest, put, call } from 'redux-saga/effects';
import { getPaymentsURL } from 'src/utils';

import { cookieManager } from '../../managers/cookieManager';
import { IAccount } from 'src/types/account';
import api from '../../managers/api';
import logger from 'src/managers/logger.manager';
import {
    ACTION_GET_DELIVERY_TIMES_REQUEST,
    ACTION_GET_DELIVERY_TIMES_LOAD,
    ACTION_GET_DELIVERY_TIMES_SUCCESS,
    ACTION_GET_DELIVERY_TIMES_ERROR,
    ACTION_GET_DELIVERY_PLANS_REQUEST,
    ACTION_GET_DELIVERY_PLANS_LOAD,
    ACTION_GET_DELIVERY_PLANS_SUCCESS,
    ACTION_GET_DELIVERY_PLANS_ERROR,
    ACTION_GET_ACTIVE_DELIVERY_PLAN_REQUEST,
    ACTION_GET_ACTIVE_DELIVERY_PLAN_LOAD,
    ACTION_GET_ACTIVE_DELIVERY_PLAN_SUCCESS,
    ACTION_GET_ACTIVE_DELIVERY_PLAN_ERROR,
    ACTION_GET_ACCOUNT_DELIVERIES_REQUEST,
    ACTION_GET_ACCOUNT_DELIVERIES_LOAD,
    ACTION_GET_ACCOUNT_DELIVERIES_SUCCESS,
    ACTION_GET_ACCOUNT_DELIVERIES_ERROR,
    ACTION_GET_CALENDAR_DELIVERY_DATES_REQUEST,
    ACTION_GET_CALENDAR_DELIVERY_DATES_LOAD,
    ACTION_GET_CALENDAR_DELIVERY_DATES_SUCCESS,
    ACTION_GET_CALENDAR_DELIVERY_DATES_ERROR,
    ACTION_SAVE_DELIVERY_CHANGES_REQUEST,
    ActionType,
    ACTION_GET_USER_CREDIT_REQUEST,
    ACTION_GET_USER_CREDIT_SUCCESS,
    ACTION_DISCARD_MEAL_PLAN_CHANGES_ERROR,
    ACTION_DISCARD_DELIVERY_PLAN_CHANGES_REQUEST,
    ACTION_APPLY_USER_CREDIT_REQUEST
} from '../action-types';

interface IResponse {
    [key: string]: any;
}

export function* sagaDeliveriesWatcher() {
    yield takeLatest(ACTION_GET_DELIVERY_TIMES_REQUEST, getDeliveryTimesWorker);
    yield takeLatest(ACTION_GET_DELIVERY_PLANS_REQUEST, getDeliveryPlansWorker);
    yield takeLatest(ACTION_GET_ACTIVE_DELIVERY_PLAN_REQUEST, getActiveDeliveryPlanWorker);
    yield takeLatest(ACTION_GET_ACCOUNT_DELIVERIES_REQUEST, getAccountDeliveriesWorker);
    yield takeLatest(ACTION_GET_CALENDAR_DELIVERY_DATES_REQUEST, getCalendarDeliveryDatesWorker);
    yield takeLatest(ACTION_SAVE_DELIVERY_CHANGES_REQUEST, saveDeliveryChangesWorker);
    yield takeLatest(ACTION_GET_USER_CREDIT_REQUEST, getUserCreditWorker);
    yield takeLatest(ACTION_APPLY_USER_CREDIT_REQUEST, applyUserCreditWorker);
    yield takeLatest(ACTION_DISCARD_DELIVERY_PLAN_CHANGES_REQUEST, discardDeliveryPlanChangesWorker);
}

function* getDeliveryTimesWorker({ payload }: ActionType<{ region: string }>) {
    try {
        yield put({ type: ACTION_GET_DELIVERY_TIMES_LOAD });
        const deliveryTimes = (yield call(() => api.get(`/deliveries/times${payload.region ? `?region=${payload.region}` : ''}`).then((res) => res.data.data))) as IResponse;
        yield put({ type: ACTION_GET_DELIVERY_TIMES_SUCCESS, payload: deliveryTimes });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_DELIVERY_TIMES_ERROR, payload: { error }});
    }
}

function* getDeliveryPlansWorker() {
    try {
        yield put({ type: ACTION_GET_DELIVERY_PLANS_LOAD });
        const deliveryPlans = (yield call(() => api.get('/delivery-plans').then((res) => res.data.data))) as IResponse;
        yield put({ type: ACTION_GET_DELIVERY_PLANS_SUCCESS, payload: deliveryPlans });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_DELIVERY_PLANS_ERROR, payload: { error }});
    }
}

function* getActiveDeliveryPlanWorker() {
    try {
        yield put({ type: ACTION_GET_ACTIVE_DELIVERY_PLAN_LOAD });

        const activeDeliveryPlan = (yield call(() => api.get('/delivery-plans/active').then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_ACTIVE_DELIVERY_PLAN_SUCCESS, payload: Array.isArray(activeDeliveryPlan) && !activeDeliveryPlan.length ? null : activeDeliveryPlan });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_ACTIVE_DELIVERY_PLAN_ERROR, payload: { error }});
    }
}

function* getAccountDeliveriesWorker() {
    try {
        yield put({ type: ACTION_GET_ACCOUNT_DELIVERIES_LOAD });

        const accountId = JSON.parse(cookieManager.get('auth') || '{}').accounts.find((acc: IAccount) => acc.type === "client")?.id || 0;

        const accountDeliveries = (yield call(() => api.get(`/accounts/${accountId}/deliveries`).then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_ACCOUNT_DELIVERIES_SUCCESS, payload: accountDeliveries });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_ACCOUNT_DELIVERIES_ERROR, payload: { error }});
    }
}

function* getCalendarDeliveryDatesWorker({ payload }: ActionType<{ isSubscription: boolean }>) {
    try {
        yield put({ type: ACTION_GET_CALENDAR_DELIVERY_DATES_LOAD });

        const auth = cookieManager.get('auth');

        const accountId = auth ? JSON.parse(cookieManager.get('auth') || '{}').accounts.find((acc: IAccount) => acc.type === "client")?.id || 0 : 0;

        const deliveryDates = (yield call(() => api.post(`/calendar-delivery-dates`, { account_id: accountId, is_subscription: !!payload.isSubscription }).then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_CALENDAR_DELIVERY_DATES_SUCCESS, payload: deliveryDates });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_CALENDAR_DELIVERY_DATES_ERROR, payload: { error }});
    }
}


function* saveDeliveryChangesWorker({ payload }: ActionType<{ delivery_plan_id: number, update_meal_plan: boolean, saved_delivery_date: string, onSuccess?: () => void, onCreditSuccess?: () => void }>) {
    try {
        const reqBodyObj = {
            update_meal_plan: payload.update_meal_plan,
            saved_delivery_date: payload.saved_delivery_date
        };
        
        const saveResponse = (yield call(() => api.post(`/delivery-plans/${payload.delivery_plan_id}/save`, reqBodyObj).then((res) => res.data.data))) as IResponse;

        if (saveResponse.success && saveResponse.type === "coupon") {
            window.location.href = `/settings`;            
        } else if (saveResponse.success && saveResponse.type === "invoice") {
            window.location.href = getPaymentsURL(`/invoices/${saveResponse.invoice.id}/pay`);
        } else if (saveResponse.success) {
            payload.onCreditSuccess && payload.onCreditSuccess();
        }

        payload.onSuccess && payload.onSuccess()

    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_DELIVERY_PLANS_ERROR, payload: { error }});
    }
}

function* getUserCreditWorker() {
    try {
        const userCredit = (yield call(() => api.get('/credit').then((res) => res.data))) as IResponse;

        if(userCredit && !isNaN(userCredit.data)){
            yield put({ type: ACTION_GET_USER_CREDIT_SUCCESS, payload: userCredit.data });
        } else {
            yield put({ type: ACTION_GET_USER_CREDIT_SUCCESS, payload: 0 });
        }
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_DELIVERY_PLANS_ERROR, payload: { error }});
    }
}

function* applyUserCreditWorker({ payload }: ActionType<{ usedCredit: number, onSuccess?: () => void}>) {
    try {
        const reqBodyObj = {
            credits_used: payload.usedCredit
        };
        const userCredit = (yield call(() => api.post('/credit/use', reqBodyObj).then((res) => res.data))) as IResponse;

        if(userCredit && !isNaN(userCredit.data)){
            yield put({ type: ACTION_GET_USER_CREDIT_SUCCESS, payload: userCredit.data });
        } else {
            yield put({ type: ACTION_GET_USER_CREDIT_SUCCESS, payload: 0 });
        }
        payload.onSuccess && payload.onSuccess()
        
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_DELIVERY_PLANS_ERROR, payload: { error }});
    }
}


function* discardDeliveryPlanChangesWorker({ payload }: ActionType<{ deliveryPlanId: number, onSuccess?: () => void }>) {
    try {

      yield call(() =>
        api.post(`/delivery-plans/${payload.deliveryPlanId}/discard-changes`).then((res) => res.data.data)
      );
  
      payload.onSuccess && payload.onSuccess();
    } catch (error) {
      logger.error(error);
      yield put({ type: ACTION_DISCARD_MEAL_PLAN_CHANGES_ERROR, payload: { error }});
    }
  }